@import '../../assets/style/constants';

section.login {
  height: 100vh;
  background: #b7c9cc;
  .login-content {
    min-width: 320px;
    max-width: 400px;
    box-shadow: 0 5px 22px #969696;
    background: #fff;
    margin: auto;
    h1 {
      padding: 20px 0;
      margin-bottom: 20px;
      font-size: 34px;
      font-weight: 200;
      color: #3EBFCC;
      border-bottom: 1px solid #dadada;
      text-align: center;
    }
    input {
      padding: 10px 0;
      background: transparent;
      border: none;
      border-radius: 0;
      color: #7d7b7b;
      border-bottom: 1px solid #3EBFCC;
      &.has-error {
        border-bottom: 1px solid #bd5353;
        color: #bd5353;
      }
    }
    button[type="submit"] {
      padding: 8px 25px;
      margin: 20px 0;
      border: none;
      color: #fff;
      background: #3ebfcc;
      border-radius: 0;
      cursor: pointer;
      font-weight: 600;
    }
    .copy {
      font-size: 12px;
      text-align: center;
    }
  }
  .inputs-box {
    padding: 20px;
  }
  .spinner {
    display: block;
    height: 19px;
    width: 19px;
    float: right;
    margin-left: 15px;
    overflow: hidden;
  }
  .button-section {
    margin-bottom: 20px;
  }
}
