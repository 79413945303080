@import './../../../assets/style/constants.scss';

header{
  &.main-header{
    width:100%;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
    position: fixed;
    z-index: 10000000;
    .logo{
      display: block;
      float: left;
      img{
        margin: 8px 0;
        height: 42px;
      }
    }

    .main-navigation{
      font-family: 'Roboto', sans-serif;
      ul{
        float: right;
        list-style: none;
        margin: 0px;
        padding: 0px;
        li{
          float: left;
          list-style: none;
          margin: 0px;
          padding: 0px;
          a{
            font-size: 14px;
            line-height: 60px;
            font-weight: 400;
            padding: 0 20px;
            display: block;
            letter-spacing:1px;
            color: #777;
            &:hover{
              color:#282828;
              text-decoration: none;
            }
            &.active{
              color:#282828;
            }
          }
        }
      }
      @media(max-width: $screen-md){
        display: none;
        width: 100%;
        float: none;
        overflow: hidden;
        position: absolute;
        top:57px;
        left: 0px;
        background-color: #111;
        ul{
          width: 100%;
          background-color: #111;
          border-top: medium none;
          box-shadow: none;
          margin-top: 0px;
          overflow-x: visible;
          padding-left: 0;
          padding-right: 0;
          li{
            display: block;
            width: 100%;
            a{
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
    .mobile-menu-navigation{
      color: #777;
      padding: 0;
      display: none;
      cursor: pointer;
      transition-property: opacity,filter;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      font: inherit;
      text-transform: none;
      background-color: transparent;
      border: 0;
      margin: 0;
      overflow: visible;
      @media(max-width: $screen-md){
        display: inline-block;
        float: right;
        margin-top: 9px;
      }
      &:hover{
        color:#282828;
      }
      &.active{
        color:#282828;
        .hamburger-box{
          .hamburger-inner{
            transform: rotate(45deg);
            transition-delay: 0.14s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            &:before{
              top: 0;
              opacity: 0;
              transition: top 0.1s ease, opacity 0.1s 0.14s ease;
            }
            &:after{
              bottom: 0;
              transform: rotate(-90deg);
              transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215,0.61,0.355,1);
            }
          }
        }
      }
      .hamburger-box{
        width: 31px;
        height: 24px;
        display: inline-block;
        position: relative;
        top: 8px;
        .hamburger-inner{
          transition-duration: 0.1s;
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          display: block;
          top: 50%;
          margin-top: -2px;
          cursor: pointer;
          transform: rotate(0deg);
          &:before{
            transition: top 0.1s 0.14s ease, opacity 0.1s ease;
            top: -10px;
            content: "";
            display: block;
          }
          &:after{
            transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
            bottom: -10px;
            content: "";
            display: block;
          }
        }
      }
    }

    &.navbar-absolute-top{
      background:none;
      position: absolute;
      z-index: 1000000;
      width: 100%;
      border:none;
      top: -2px;
      // transition: all 0.5s ease;
      .mobile-menu-navigation{
        color: inherit;

      }
      .main-navigation{
        ul{
          li{
            a{
              color: #fff;

            }
          }
        }
      }
    }
    &.open{
      background-color: #111;
    }
  }
  &.inner-header{
    background-color: #fff;
    position: relative;
    box-shadow: 0 2px 1px 0 rgba(0,0,0,.1)!important;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    .logo{
      display: block;
      float: left;
      img{
        margin: 5px 0;
        height: 40px;
      }
    }
    .notification-dropdown{
      float: right;
      height: 50px;
      display: block;
      border-left: 1px solid #eee;
      position: relative;
      .dropdown-toggle{
        color: #4b4c4d;
        padding: 0 20px;
        display: block;
        line-height: 50px;
        i{
          font-size: 15px;
          margin-top: 18px;
          color: #A9B2B8;
        }
        .badge{
          vertical-align: 12px;
          margin-left: -11px;
          background-color: #0CC9F5;
          padding: 3px 6px;
          border-radius:30px;
          padding: 0;
          line-height: 15px;
          height: 15px;
          width: 15px;
          text-align: center;
        }
      }
      &.open{
        background-color: #e0e4e8;
        .dropdown-toggle{
          i{
            color: #262d33;
          }
        }
      }
      &:hover{
        .dropdown-toggle{
          i{
            color: #262d33;
          }
        }
      }
      a{
        &:hover, &:active{
          text-decoration: none;
        }
      }
      .dropdown-menu{
        right: -1px;
        margin-top: 0px;
        // -webkit-border-top-left-radius: 0px;
        // -webkit-border-top-right-radius: 0px;
        // -moz-border-radius-topleft: 0px;
        // -moz-border-radius-topright: 0px;
        // border-top-left-radius: 0px;
        // border-top-right-radius: 0px;
        border-radius: 0;
        border-top:0;
        border-color: #e0e4e8;
        padding-top: 0;
        padding-bottom: 0;
        .panel-heading {
          background-color: #fff;
          padding: 10px 10px;
          border-bottom: 1px solid #eee;
          color: #717171;
          font-size: 13px;
          strong{
            min-width: 200px;
            display: block;
            font-size: 12px;
          }
          &.without-border{
            border-bottom: none;
          }
        }
        ul{
          list-style: none;
          padding-left:0;
          margin-bottom: 0;
          li{
            padding: 5px 10px;
            font-size: 12px;
            border-bottom: 1px solid #ebeff6;
            &:last-child{
              border-bottom: none;
            }
            a{
              color: #575757;
            }
          }
        }
      }
      ul.dropdown-menu{
        padding-bottom: 5px;
      }
    }
    .user-dropdown{
      float: right;
      padding-top: 9px;
      padding-bottom: 12px;
      .avatar {
        border: 1px solid rgba(255,255,255,0.2);
        display: block;
        border-radius: 500px;
        white-space: nowrap;
        width: 42px;
        float: left;
        img {
          border-radius: 500px;
          width: 100%;
        }
      }
      .avatar-name{
        margin-top: 10px;
        display: inline-block;
        margin-left: 5px;
        font-family: Helvetica;
        font-weight: 300;
        color:#333;
        font-size: 13px;
      }
      a{
        &:hover, &:active{
          text-decoration: none;
        }
      }
      .dropdown-menu{
        left: auto;
        right: -15px;
        margin-top: 1px;
        -webkit-border-top-left-radius: 0px;
        -webkit-border-top-right-radius: 0px;
        -moz-border-radius-topleft: 0px;
        -moz-border-radius-topright: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top:0;
        border-color: #e0e4e8;
        padding-top: 0;
      }
    }
    .balance-box{
      float: right;
      padding: 6px 20px;
      border-left: 1px solid #eee;
      .balance-box-element{
        display: block;
        float: left;
        margin-right: 25px;
        &:last-child{
          margin-right: 0;
        }
        span{
          text-align: center;
        }
        .block{
          display: block;
          color: #717171;
          font-size: 13px;
        }
      }
    }
    .btn{
      margin-top: 7px;
      margin-right: 10px;
    }
    .logout{
      float: right;
      height: 50px;
      line-height: 50px;
      display: block;
      width: 50px;
      text-align: center;
      margin-right: -15px;
      cursor: pointer;
      background-color: #4BC5C3;
      transition: all 0.4s;
      i{
        font-size: 15px;
        margin-top: 18px;
        color: #fff;
      }

      &:hover {
        background: #348a88;
      }
    }
    .users-setings{
      float: right;
      height: 50px;
      line-height: 50px;
      display: block;
      width: 50px;
      text-align: center;
      background-color: #FFB230;
      i{
        font-size: 15px;
        margin-top: 18px;
        color: #fff;
      }
    }
  }
}
.hamburger-box .hamburger-inner, .hamburger-box .hamburger-inner:before, .hamburger-box .hamburger-inner:after{
  width: 30px;
  height: 2px;
  background-color: #fff;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.open .hamburger-box .hamburger-inner, .open .hamburger-box .hamburger-inner:before, .open .hamburger-box .hamburger-inner:after{
  background-color:#fff;
}
.navbar-fixed-top .hamburger-box .hamburger-inner, .navbar-fixed-top .hamburger-box .hamburger-inner:before, .navbar-fixed-top .hamburger-box .hamburger-inner:after{
  background-color: #282828;
}
.navbar-fixed-top.open .hamburger-box .hamburger-inner, .navbar-fixed-top.open .hamburger-box .hamburger-inner:before, .navbar-fixed-top.open .hamburger-box .hamburger-inner:after{
  background-color: #fff;
}
