
.CreateContainer{
    display: flex;
    position: absolute;
    z-index: 100;
    width:100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    top: 0;
    left:0;
    .modalContent{
        margin: auto;
        min-width:300px;
        background: white;
        padding-bottom: 24px;
        .modalTitle{
            border-bottom: 1px solid #cacaca;
            .Title{
                margin: 0 0 20px;
                display: block;
                color: #6c757d;
                font-size: 17px;
                padding: 15px 12px 0 15px;
                button{
                    border: none;
                    background: 0 0;
                    outline: 0;
                    float: right;
                    float: right;
                    i{
                        color: #b5b5b5;
                        &:hover{
                            color: black;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .modalBody{
            width:100%;
            padding: 24px;
            max-height: 500px;
            overflow: scroll;
        };
        .modalFooter{
            padding: 0 24px 0 24px;
            text-align: end;
        }
    }
}