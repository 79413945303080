.modalContainer{
    display: flex;
    position: absolute;
    z-index: 100;
    width:100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    top: 0;
    left:0;
    .AddMoneyContent{
        margin: auto;
        width: 300px;
        background: white;
        .modalTitle{
            border-bottom: 1px solid #cacaca;
            .Title{
                margin: 0 0 20px;
                display: block;
                color: #6c757d;
                font-size: 17px;
                padding: 15px 12px 0 15px;
                button{
                    border: none;
                    background: 0 0;
                    outline: 0;
                    float: right;
                    float: right;
                    i{
                        color: #b5b5b5;
                        &:hover{
                            color: black;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        .modalBody{
            padding: 20px;
            .autoComplite{
                position: absolute;
                width:100%;
                z-index: 5;
                border: 1px solid lightgrey;
                border-radius: 5px;
                background: white;
                max-height: 150px;
                overflow: scroll;
                ul{
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    li{
                        text-align: center;
                        padding: 10px;
                        cursor: pointer;
                        &:hover{
                            background: lightgray
                        }
                    }
                }
            }
        }
    }
}