.box-wrapper {
  background-color: #FFF;
  border: 1px solid #e0e4e8;
  border-top: none;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  overflow: visible;
  .value {
    font-size: 40px;
    margin-bottom: 0;
  }
  .title {
    margin-bottom: 0;
  }
}
