.minBid{
    .my-table{
        width: 100%;
        min-width: 800px;
        overflow-x: auto;
        background: #fff;
        .my-table-header{
          .my-table-row{
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 25% 25% 25%;
            grid-template-columns:25% 25% 25%;
            .my-table-column{
              padding: 15px 10px
            }
          }
        }
        .my-table-body{
          overflow-y: auto;
          min-height: 300px;
          max-height: 500px;
          .my-table-row{
            display: -ms-grid;
            display: grid;
            -ms-grid-columns:25% 25% 25%;
            grid-template-columns: 25% 25% 25%;
            .my-table-column{
              padding: 15px 10px
            }
          }
        }
      }
}