.todays-boxes{
    background-color: #FFF;
    border-bottom: 1px solid #e0e4e8;
    border-top: none;
    padding: 20px;
    overflow: visible;
    .titile{
        font-size: 25px;
        margin-bottom: 0;
    }
    .number{
        font-size: 20px;
        margin-bottom: 0;
    }
}
.top5countries{
    background-color: #FFF;
    border-top: 1px solid #e0e4e8;
    padding: 10px
}