.logo-image-placeholder {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 200px;
  img {
    max-width: 100%;
    display: inline-block;
    margin: auto;
    vertical-align: middle;
  }
}
.inner-section {
  .shadow-widget {
    &.tenants-page-widget {
      padding: 20px;
    }
  }
}

.footer-line {
  border-top: 1px solid #ccc;
  padding: 10px 20px 0;
  margin: 10px -20px -20px;
  button {
    width: auto;
  }
}

