.actionsMenu{
    background: white;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    z-index: 10;
    border: 1px solid lightgray;
    top: 37px;
    right: 0;
    ul{
        width:100%;
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            width:100%;
            color: black;
            padding: 6px 30px;
            font-size: 15px;
            &:hover{
                background: lightgray
            }
        }
    }
}
.actionsBtn{
    background: white;
    border: 1px solid lightgray;
    border-radius: 5px;
    position: relative;
    &:focus{
      box-shadow: none
    }
  }