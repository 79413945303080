@import '../../../assets/style/constants';
@mixin buttonStyle($color, $bg, $borderColor) {
    background: $bg;
    color : $color;
    border-color: $borderColor;
}

button {
    box-sizing: border-box;
    border: 2px solid;
    transition: all .2s ease-in;
    margin-right: 10px;
    &>a {
        color : $inputBordersColor !important;
        text-decoration: $inputBordersColor;
        &:hover {
            color: $mainColor !important;
            text-decoration: none !important;
        }
    }
    &:focus {
        outline: none;
    } 
    &.main {
        padding: 5px 20px;
        @include buttonStyle($white, $mainColor, $mainColor);
    }
    &.not-active {
        border: none;
        @include buttonStyle($default, $transparent, $mainColor);
    }
    
    &:hover {
        @include buttonStyle($mainColor, $transparent, $mainColor);
        cursor: pointer;
    }
}