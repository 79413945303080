.todays-container{
    display: flex;
    border-bottom: 1px solid #e0e4e8;
    .todays-boxes{
        background-color: #FFF;
        border-top: none;
        padding: 20px;
        overflow: visible;
        border-bottom: none;
        &:hover{
            background: #e0e4e8;
        }
        .titile{
            font-size: 25px;
            margin-bottom: 0;
        }
        .number{
            font-size: 20px;
            margin-bottom: 0;
        }
    }
}
.chart-footer{
    border-top: 1px solid  #e0e4e8;
    padding-top: 10px;
}