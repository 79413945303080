.logs{
    .my-table{
        width: 100%;
        min-width: 800px;
        overflow-x: auto;
        background: #fff;
        .my-table-header{
          .my-table-row{
            display: -ms-grid;
            display: grid;
            -ms-grid-columns: 20% 20% 20% 40%;
            grid-template-columns:  20% 20% 20% 40%;
            .my-table-column{
              padding: 15px 10px
            }
          }
        }
        .my-table-body{
          overflow-y: auto;
          max-height: 500px;
          margin-bottom: 20px;
          .my-table-row{
            display: -ms-grid;
            display: grid;
            -ms-grid-columns:  20% 20% 20% 40%;
            grid-template-columns:  20% 20% 20% 40%;
            .my-table-column{
              padding: 15px 10px
            }
          }
        }
        .my-table-footer{
          margin-bottom: 20px;
          font-weight: bold;
        }
      }
}