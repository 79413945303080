.paymentPart{
    display: flex;
    .stripe{
        cursor: pointer;
        width: 150px;
        border: 1px solid #eaedef;
        border-radius: 5px;
        overflow: hidden;
        padding: 5px;
        .stripeHeader{
            height: 25px;
            background-image: url('../../assets/imgs/creditcards.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .paypal{
        cursor: pointer;
        width: 150px;
        border: 1px solid #eaedef;
        border-radius: 5px;
        overflow: hidden;
        padding: 5px;
        margin-right: 5px;
        .paypalHeader{
            height: 25px;
            margin: auto;
            width: 90px;
            background-image: url('../../assets/imgs/paypal.png');
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
    .coinPayments{
        cursor: pointer;
        width: 150px;
        border: 1px solid #eaedef;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 5px;
        padding: 5px;
        .coinPaymentsHeader{
            display: flex;
            justify-content: space-between;
            width:100%;
            height: 20px;
            .Bitcoin{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/bitcoin.png')
            }
            .BlackCoin{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/blackcoin.png')
            }
            .Dash{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/dash.png')
            }
            .Ether{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/ether.png')
            }
            .Namecoin{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/namecoin.png')
            }
            .LitecoinTestnet{
                width: 20px;
                background-size: contain;
                background-image: url('../../assets/imgs/coins/LitecoinTestnet.png')
            }
        }
    }
    .wireTransfer{
        cursor: pointer;
        width: 150px;
        border: 1px solid #eaedef;
        border-radius: 5px;
        overflow: hidden;
        margin-right: 5px;
        padding: 5px;
        display: flex;
        .wireTransferHeader{
            margin:auto;
            background-size: contain;
            background-image: url('../../assets//imgs/wiretransfer.png');
            width: 100px;
            height: 40px;
            background-repeat: no-repeat;
        }
    }
}
.CoinPaymentsSettings{
    .CoinPaymentsSettingsPart{
        margin-bottom: 20px;
        width: 320px;
        margin-top: 10px;
        padding: 20px;
        border: 1px solid #eaedef;
        border-radius: 5px;
    }
}
.footer-buttons{
    padding-top: 10px;
    padding-bottom: 10px;
    a{
        border: 1px solid #eaedef
    }
}